<template>
	<div class="user flex_e">
		<el-dropdown @command="handleCommand">
			<span class="el-dropdown-link">
				{{user.name}}<i class="el-icon-arrow-down el-icon--right"></i>
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item>退出登录</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</div>
</template>

<script>
	import {
		info
	} from '../apis/userInfo.js';
	import vueStore from '../store/index.js'
	export default {
		data() {
			return {
				user: {}
			}
		},
		created() {
			this.getInfo()
		},
		methods: {
			getInfo() {
				info().then((res) => {
					// console.log(res.data)
					this.user = res.data
				})
			},
			handleCommand() {
				vueStore.dispatch('updateRoute', [])
				localStorage.clear()
				sessionStorage.clear()
				this.$router.push('/login', () => {})

			}
		}
	}
</script>

<style lang="less" scoped>
	.user {
		padding: 20px;
		width: 100%;
		box-sizing: border-box;
		background-color: #545C64;

		.el-dropdown {
			cursor: pointer;
			color: #fff;
		}

		//>=768的设备 移动端

	}

	@media screen and (max-width: 767px) {
		.user {
			padding: 10px;

			.el-dropdown {
				font-size: 12px;
				color: #fff;

			}

			/deep/.el-dropdown-menu {

				.el-dropdown-item {
					font-size: 12px;
				}
			}
		}
	}
</style>