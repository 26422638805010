
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  token:'',
  },
  getters: {
	  getUserToken (state) {
	    return state.token
	  },
	 
  },
  mutations: {
	 setToken(state,val){
	 		  state.token=val
	 		  // console.log('拿到了',state.token)
	 } 
  },
  actions: {
	 updateUserToken (state,token) {
		 // console.log('仓库token',state,token)
		 state.commit('setToken',token)
	 }
  },
  modules: {
  }
})
