import {
	http
} from '../util/request.js'


export const info = () => {
	return http.get('api/admin/info', {})
}
export const menu = () => {
	return http.post('api/menu/list', {})
}
