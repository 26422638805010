  const allroutes = [{
  		path: '/login',
  		name: 'Login',
  		component: () => import('@/views/login.vue')
  	},
  	{
  		path: '/home',
  		name: 'Home',
  		component: import('@/views/index.vue'), //路由按需加载
  		meta: {
  			title: '首页',
  			icon: 'el-icon-s-home',
  		},
		redirect:'/join',
  		children: [{
  			path: '/join',
  			name: 'Join',
  			meta: {
  				title: '报名统计',
  				icon: '',
  			},
  			component: () => import('@/views/join.vue'),
  		}, 
		{
			path: '/team',
			name: 'Team',
			meta: {
				title: '业绩统计',
				icon: '',
			},
			component: () => import('@/views/team.vue'),
		},
		]

  	},
  	{
  		path: '/mangeAccount',
  		redirect: "/accountIndex",
  		name: 'MangeAccount',
  		component: import('@/views/index.vue'),
  		meta: {
  			title: '账号管理',
  			icon: 'el-icon-s-custom',
  		},
  		children: [{
  			path: '/accountIndex',
  			name: 'AccountIndex',
  			meta: {
  				title: '账号列表',
  				icon: ''
  			},
  			component: () => import('@/views/account/accountIndex.vue'),
  		}, ]
  	},
  	// {
  	// 	path: '/mangeInfo',
  	// 	redirect: "/info",
  	// 	name: 'MangeInfo',
  	// 	component: import('@/views/index.vue'),
  	// 	meta: {
  	// 		title: '信息管理',
  	// 		icon: 'el-icon-s-comment',
  	// 	},
  	// 	children: [{
  	// 			path: '/info',
  	// 			name: 'Info',
  	// 			meta: {
  	// 				title: '信息录入',
  	// 				icon: ''
  	// 			},
  	// 			component: () => import('@/views/marketers/infoList.vue'),
  	// 		},


  	// 	]
  	// },
  	{
  		path: '/class',
  		redirect: "/classList",
  		name: 'Class',
  		component: import('@/views/index.vue'),
  		meta: {
  			title: '课程管理',
  			icon: 'el-icon-s-platform',
  		},
  		children: [{
  				path: '/classList',
  				name: 'ClassList',
  				meta: {
  					title: '在线课程',
  					icon: ''
  				},
  				component: () => import('@/views/class/classList.vue'),
  			},
  			{
  				path: '/checkinfo',
  				name: 'Check',
  				meta: {
  					title: '报名列表',
  					icon: ''
  				},
  				component: () => import('@/views/marketers/checkInfo.vue'),
  			},
  			{
  				path: '/checkClass',
  				name: 'CheckClass',
  				meta: {
  					title: '课消列表',
  					icon: ''
  				},
  				component: () => import('@/views/class/checkClass.vue'),
  			},
  		]
  	},
  	{
  		path: '/client',
  		redirect: "/info",
  		name: 'Client',
  		component: import('@/views/index.vue'),
  		meta: {
  			title: '学员管理',
  			icon: 'el-icon-s-custom',
  		},

  		children: [
			{
			  path: '/info',
			  name: 'info',
			  meta: {
			  	title: '学员列表',
				icon:''
			  },
			  component: () => import('@/views/marketers/infoList.vue'),
			},
			{
  				path: '/clientManage',
  				name: 'ClientManage',

  				meta: {
  					title: '学员分配',
  					icon: ''
  				},
  				component: () => import('@/views/clients/clientManage.vue'),
  			},
  			{
  				path: '/clientRecord',
  				name: 'ClientRecord',

  				meta: {
  					title: '回访记录',
  					icon: ''
  				},
  				component: () => import('@/views/clients/clientRecord.vue'),
  			},
  		]
  	},
  	{
  		path: '/finance',
  		redirect: "/checkLog",
  		name: 'Finance',
  		component: import('@/views/index.vue'),
  		meta: {
  			title: '财务管理',
  			icon: 'el-icon-s-finance',
  		},

  		children: [{
  				path: '/checkLog',
  				name: 'CheckLog',

  				meta: {
  					title: '缴费审核',
  					icon: ''
  				},
  				component: () => import('@/views/finance/checkLog.vue'),
  			},
  			{
  				path: '/checkRefund',
  				name: 'checkRefund',

  				meta: {
  					title: '退费审核',
  					icon: ''
  				},
  				component: () => import('@/views/finance/checkRefund.vue'),
  			},
  			{
  				path: '/classCheck',
  				name: 'classCheck',

  				meta: {
  					title: '入账列表',
  					icon: ''
  				},
  				component: () => import('@/views/finance/classCheck.vue'),
  			}
  		]
  	},
  ]

  export default allroutes