import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from '../router/index.js'
import vueStore from '../store/index.js'
import userStore from '../store/useUserStore.js'

import {
	http
} from '@/util/request'
import allroutes from '../router/permissionRoutes.js'; //本地所有路由
import whiteRouter from '../router/whiteRouter.js'



import Vue from 'vue'
const role = async () => {
	const data = await http.post('api/menu/list', {})
	return data.data.path
	
}

function deepClone(obj) {
	const objClone = Array.isArray(obj) ? [] : {}
	if (obj && typeof obj === 'object') {
		for (const key in obj) {
			if (obj[key] && typeof obj[key] === 'object') {
				objClone[key] = deepClone(obj[key])
			} else {
				objClone[key] = obj[key]
			}
		}
	}
	return objClone
}

NProgress.configure({
	showSpinner: false
})
router.beforeEach(async (to, from, next) => {
	NProgress.start()
	if (userStore.getters.getUserToken == '') {
		if (to.path == '/login' || to.path == '/' ) {
			next()
			NProgress.done()
		} else {
			const token = localStorage.getItem('access_token')
			if (token == '' || token == undefined || token == null) {
				next('/login')
				NProgress.done()
			} else if (vueStore.getters.getRoute.length == 0) {
				// 获取路由菜单
				const roles = await role() // 用户可跳转的路由
				console.log('执行获取菜单', roles)
				if (roles.length === 0) {
					next('/login')
					NProgress.done()
				}
				const asyncRoutes = []
				allroutes.forEach((item) => {
					roles.forEach((val) => {
						if (item.path == val) {
							asyncRoutes.push(item)
							router.options.routes = asyncRoutes
                            
						}
					})
				})
				// console.log('5555555555',router)
				vueStore.dispatch('updateRoute', asyncRoutes)
				next(to.path)
			} else {
				next()
				NProgress.done()
			}
		}

	} else {
		if (whiteRouter.indexOf(to.path) !== -1) {
			next()
			NProgress.done()
		} else {
			next()
			NProgress.done()
		}
	}
})