import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import '@/plugin/permission'

//reset.css
import "./assets/css/reset.css"

//ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
ElementUI.Dialog.props.closeOnClickModal.default = false
ElementUI.Dialog.props.closeOnPressEscape.default = false
Vue.use(ElementUI)

//echarts
// import echarts from 'echarts';
// Vue.prototype.$echarts = echarts;
// Vue.use(echarts)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')