<template>
	<div class="menu_box">
		<el-menu @select="handleSelect" :default-active="path" router :unique-opened="false"
			:default-openeds='defaultOpeneds' class="left-nav el-menu-vertical-demo" background-color="#545c64"
			text-color="#fff" active-text-color="#ffd04b">

			<template v-for="menu in routeArr">
				<!-- (无子菜单) -->
				<el-menu-item v-if="!menu.children && !menu.hidden" :key="menu.path" :index="menu.path">
					<i class="iconfont" :class="menu.meta.icon"></i>
					<span slot="title">{{menu.meta.title}}</span>
				</el-menu-item>

				<!--  (有子菜单)-->
				<el-submenu v-else :key="menu.path" :index="menu.path">
					<template slot="title">
						<i class="iconfont" :class="menu.meta.icon"></i>
						<span>{{menu.meta.title}}</span>
					</template>

					<el-menu-item :key="subMenu.path" v-for="subMenu in menu.children"
						:index="subMenu.path">{{subMenu.meta.title}}</el-menu-item>
				</el-submenu>

			</template>


		</el-menu>
	</div>
</template>

<script>
	import router from '../router/index.js'

	export default {
		data() {
			return {
				routeArr: [],
				path: '',
				defaultOpeneds: [],
				post_id: ''
			}
		},
		created() {

			this.post_id = localStorage.getItem('post_id')
			let data = router.options.routes
			for (let i = 0; i < data.length; i++) {
				if (this.post_id == 3) { //财务不看统计
					if ((data[i].meta && data[i].path != '/' || data[i].meta && data[i].path != '/login') && data[i]
						.path != '/home') {
						this.routeArr.push(data[i])
					}
				} else { //其余角色
					if (data[i].meta && data[i].path != '/' || data[i].meta && data[i].path != '/login') {
						this.routeArr.push(data[i])
					}
				}
			}
            //解决路由刷新不是当前页面问题
			if (window.sessionStorage.getItem('activePath')) {
				this.path = window.sessionStorage.getItem('activePath')
				this.$router.push(this.path, () => {})
			} else {
				this.path = router.options.routes[0].redirect
				this.$router.push(this.path, () => {})

			}
			



		},
		watch: {
			path(newValue) {
				// console.log('新值', newValue);
				this.path=newValue
				this.$router.push(newValue, () => {})
			}
		},
		methods: {
			handleSelect(key, keyPath) {
				// console.log(key, keyPath);
				window.sessionStorage.setItem('activePath', key)
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			}
		}
	}
</script>

<style lang="less">
	.menu_box {
		height: 100vh;

		.el-menu-vertical-demo {
			height: 100%;

			.iconfont {
				color: #fff;
				margin-right: 5px;
			}
		}
	}
</style>