const route= [
	{
	  path: '/',
	redirect:"/login",
	  component:() => import('@/views/login.vue')
	},
  {
    path: '/login',
    name: 'Login',
    component:() => import('@/views/login.vue')
  }
]

export default route