import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  routes:[]
  },
  getters: {
	  getRoute (state) {
	    return state.routes
	  },
  },
  mutations: {
	  changeRotes(state,value){
		// console.log('改变仓库数据',state,value)  
		state.routes=value
	  },
  },
  actions: {
	 updateRoute (state,routes) {
		 // console.log('仓库数据更新',state,routes)
		 state.commit('changeRotes',routes)
	 },
  },
  modules: {
  }
})
