<template>
	<div id="app">
		<!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
		<router-view />
	</div>
</template>
<script>
	export default {
	  name: 'App'
	}
</script>

<style lang="less">
	#app {
		height: 100%;
		// max-width:100vw;
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.flex_c {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.flex_v {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.flex_a {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	.flex_e {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.flex_b {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>