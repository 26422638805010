<template>
	<div class="flex menu_drop">
		<el-dropdown trigger="click" @command="getValue" @visible-change='getflag' :style="{width:(1/routeArr.length)*100 + '%'}"  class="menu" v-for="(item,index) in routeArr" :key="index">
		  <el-button @click.stop="tabClick(item)"   :class="currentPath==item.path ? 'active':''" >
		    {{item.meta.title}}<i v-if="index>0" class="el-icon-arrow-down el-icon--right"></i>
		  </el-button>
		  <el-dropdown-menu slot="dropdown">
		    <el-dropdown-item :command='value.path'  v-for='(value,i) in item.children' :key="i">{{value.meta.title}}</el-dropdown-item>
		  </el-dropdown-menu>
		</el-dropdown>
	</div>
</template>

<script>
	import router from '../router/index.js'
	export default {
		data(){
			return{
				routeArr:[],
				currentPath:'',
				flag:false,
				post_id:''
			}
		},
		created() {
			this.post_id = localStorage.getItem('post_id')
			let data=router.options.routes
			for(let i=0;i<data.length;i++){
				if(this.post_id==3){//财务不看统计
					if((data[i].meta && data[i].path!='/' || data[i].meta && data[i].path!='/login') && data[i].path!='/home'){
						this.routeArr.push(data[i])
					}
				}else{//其余角色
					if(data[i].meta && data[i].path!='/' || data[i].meta && data[i].path!='/login'){
						this.routeArr.push(data[i])
					}
				}
			}
			//解决路由刷新不是当前页面问题
			if (window.sessionStorage.getItem('activePath')) {
				this.currentPath = window.sessionStorage.getItem('activePath')
			} else {
				this.currentPath = router.options.routes[0].redirect
			
			}
			this.$router.push(this.currentPath,()=>{})
			
		},
		watch: {
			currentPath(newValue) {
				// console.log('新值', newValue);
				this.currentPath=newValue
				this.$router.push(newValue, () => {})
			}
		},
		methods: {
			tabClick(e){
				console.log(e)
				this.currentPath=e.path
				if(this.flag){
					this.flag=false
				}
			},
			getValue(command){
				// console.log('获取下拉框值',command)
				window.sessionStorage.setItem('activePath', command)
				this.$router.push(command,() => {})  
				  if(this.flag){
					  this.flag=false
				  }
			},
			getflag(e){
				console.log(e)
				this.flag=e
			}
		}
	}
</script>

<style lang="less" scoped>
	.menu_drop{
		width:100%;
		flex-wrap: wrap;
	}
	.menu{
		/deep/.el-button--primary{
			border-color: none;
		}
		.el-button{
			width:100%;
			border-radius: 0;
			background: none;
			border-color: none!important;
			background-color: #545c64;
			color:#fff;
			display: flex;
			justify-content: center;
			font-size:12px;
			&.active{
				color:#ffd04b;
			}
		}
	}
	.menu:not(:last-child){
	} 
</style>