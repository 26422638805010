<template>
	<div>
		<!-- pc端显示 -->
		<div class="content flex_b hidden-md-and-down">
			<div class="left ">
				<Nav></Nav>

			</div>
			<div class="right">
				<div class="r_bottom">
					<User></User>
					<router-view></router-view>
				</div>
			</div>

		</div>
		<div class="box flex hidden-md-and-up">
			<Header></Header>
			<User></User>
			<div class="bottom">

				<router-view></router-view>
			</div>

		</div>
	</div>
</template>

<script>
import Nav from '../components/nav.vue'
import Header from '../components/header.vue'
import User from '../components/user.vue'
export default {
	components: {
		Nav,
		Header,
		User
	},
	data() {
		return {

		}
	}
}
</script>

<style lang="less" scoped>
.content {
	height: 100%;

	.left {
		flex: 0 0 200px;
		height: 100%;
	}

	.right {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		flex: 1;
		background-color: #f1f1f1;

		.r_bottom {
			height: 100vh;
		}


	}
}

.box {
	flex-direction: column;
	background-color: #f1f1f1;
	min-height: 100vh;

	.bottom {
		flex: 1;
	}
}
</style>