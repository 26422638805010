
import axios from 'axios';
import router from "@/router";
//引入路由
import { Message } from "element-ui"
import Qs from 'qs'
import vueStore from '../store/index.js'

axios.defaults.baseURL = "https://sxy-crm-test.zgtingmanyi.com/";

axios.defaults.withCredentials = false;

//请求拦截器
axios.interceptors.request.use(config => {//config是一个对象
  let token = localStorage.getItem('access_token');
  //带token
  config.headers['Authorization'] = token;
  return config
})

//响应拦截器
axios.interceptors.response.use(res => {//res是一个对象
  // console.log("响应拦截",res);
  if (res) {
    let data = res.data;
    if (data !== undefined && data.code !== undefined && data.msg !== undefined) {
      //解构code和msg
      let { code, msg } = data;

      if (code === 1) {//成功
        //弹出提示
        // Message({
        //   //this指向有问题，需要引入element-ui中message
        //   type:"success",
        //   message:msg
        // })
      } else if (code === 0) {
        Message.error(msg);
      } else if (code == 401) {
        router.push("/")
      }

    }

    return res;
  }

}, err => {
	if(err.response.status==401){
		Message.error("登录失效")
		vueStore.dispatch('updateRoute',[])
		localStorage.clear()
		sessionStorage.clear()
		 router.push("/login",()=>{});//这里没有vue实例  所以需要new一个实例才可以用push
	}else if(err.response.status==422){
			Message.error(err.response.data.message)
		
	}
	else{
		Message.error("请求错误")
		// vueStore.dispatch('updateRoute',[])
		// localStorage.clear('access_token')
		//  router.push("/login",()=>{});//这里没有vue实例  所以需要new一个实例才可以用push
	} 
})

//暴露出去
export const http= {
  //get请求方式
  get(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params })
        .then(res => {
          if (res) {
            resolve(res.data);//请求成功
          }
        })
        .catch(err => {
          reject(err);//请求失败
        })
    })
  },
  delete(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.delete(url, { params: params })
        .then(res => {
          if (res) {
            resolve(res.data);//请求成功
          }
        })
        .catch(err => {
          reject(err);//请求失败
        })
    })
  },
  getExecl(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(url, { params: params }, { responseType: "blob" })
        .then(res => {
          if (res) {
            resolve(res.data);//请求成功
          }
        })
        .catch(err => {
          reject(err);//请求失败
        })
    })
  },
  //post请求放方式
  post(url, params = {}) {
    return new Promise((resolve, reject) => {
      axios.post(url, params)
        .then(res => {
          if (res) {
            resolve(res.data)//请求成功
          }
        })
        .catch(err => {
          reject(err)//请求失败
        })
    })
  },
  axios

}
